import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "SleepScreen",
    component: () =>
      import(/* webpackChunkName: "SleepScreen" */ "@/views/SleepScreen")
  },
  {
    path: "/home",
    name: "HomeScreen",
    component: () =>
      import(/* webpackChunkName: "HomeScreen" */ "@/views/HomeScreen")
  },
  {
    path: "/map/:type",
    name: "MapScreen",
    component: () =>
      import(/* webpackChunkName: "MapScreen" */ "@/views/MapScreen")
  },
  {
    path: "/objects/:city/:type",
    name: "ObjectsScreen",
    component: () =>
      import(/* webpackChunkName: "ObjectsScreen" */ "@/views/ObjectsScreen")
  },
  {
    path: "/object/:id",
    name: "ObjectScreen",
    component: () =>
      import(/* webpackChunkName: "ObjectScreen" */ "@/views/ObjectScreen")
  },
  {
    path: "/event/:id",
    name: "EventScreen",
    component: () =>
      import(/* webpackChunkName: "EventScreen" */ "@/views/EventScreen")
  },
  {
    path: "/story/:id",
    name: "StoryScreen",
    component: () =>
      import(/* webpackChunkName: "StoryScreen" */ "@/views/StoryScreen")
  },
  {
    path: "/sarov",
    name: "SarovScreen",
    component: () =>
      import(/* webpackChunkName: "SarovScreen" */ "@/views/Cities/SarovScreen")
  },
  {
    path: "/events",
    name: "EventsScreen",
    component: () =>
      import(
        /* webpackChunkName: "EventsScreen" */ "@/views/EventsScreen"
      )
  },
  {
    path: "/diveevo",
    name: "DiveevoScreen",
    component: () =>
      import(
        /* webpackChunkName: "DiveevoScreen" */ "@/views/Cities/DiveevoScreen"
      )
  },
  {
    path: "/sos",
    name: "SosScreen",
    component: () =>
      import(/* webpackChunkName: "SosScreen" */ "@/views/SosScreen")
  },
  {
    path: "/piligrim",
    name: "PiligrimScreen",
    component: () =>
      import(/* webpackChunkName: "PiligrimScreen" */ "@/views/PiligrimScreen")
  },
  {
    path: "/eatandsleep/:city/:type",
    name: "EatAndSleepScreen",
    component: () =>
      import(
        /* webpackChunkName: "EatAndSleepScreen" */ "@/views/EatAndSleepScreen"
      )
  },
  {
    path: "/calendar",
    name: "CalendarScreen",
    component: () =>
      import(/* webpackChunkName: "CalendarScreen" */ "@/views/CalendarScreen")
  },
  {
    path: "/fact/:id",
    name: "ArticleScreen",
    component: () =>
      import(/* webpackChunkName: "ArticleScreen" */ "@/views/ArticleScreen")
  },
  {
    path: "/capitalOfTheNorth",
    name: "CapitalOfTheNorth",
    component: () =>
      import(/* webpackChunkName: "CapitalOfTheNorth" */ "@/views/CapitalOfTheNorth")
  },
  {
    path: "/liveNorth",
    name: "LiveNorth",
    component: () =>
      import(/* webpackChunkName: "LiveNorth" */ "@/views/LiveNorth")
  },
  {
    path: "/mobileAppScreen",
    name: "MobileAppScreen",
    component: () =>
      import(/* webpackChunkName: "MobileAppScreen" */ "@/views/MobileAppScreen")
  },
  {
    path: "/yearMemoryScreen",
    name: "YearMemoryScreen",
    component: () =>
      import(/* webpackChunkName: "YearMemoryScreen" */ "@/views/YearMemoryScreen")
  },
  {
    path: "/poll",
    name: "Poll",
    component: () =>
      import(/* webpackChunkName: "PollScreen" */ "@/views/PollScreen")
  },
  {
    path: "/transport",
    name: "Transport",
    component: () =>
      import(/* webpackChunkName: "Transport" */ "@/views/TransportScreen")
  },
  {
    path: "/transport/:screen",
    name: "TransportScreen",
    component: () =>
      import(/* webpackChunkName: "Transport" */ "@/views/TransportScreen")
  },
  {
    path: "/transport/:screen/:id",
    name: "TransportScreenId",
    component: () =>
      import(/* webpackChunkName: "Transport" */ "@/views/TransportScreen")
  },
  {
    path: "/guide",
    name: "Guide",
    component: () =>
      import(/* webpackChunkName: "GuideScreen" */ "@/views/GuideScreen")
  },
  {
    path: "/travelGuide",
    name: "TravelGuide",
    component: () =>
      import(/* webpackChunkName: "TravelGuideScreen" */ "@/views/TravelGuideScreen")
  },
  {
    path: "/excursions",
    name: "Excursions",
    component: () =>
      import(/* webpackChunkName: "ExcursionsScreen" */ "@/views/ExcursionsScreen")
  }
];

const router = new VueRouter({
  mode: "",
  // base: process.env.BASE_URL,
  routes
});

export default router;
